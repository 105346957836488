<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>

    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-plus"></i> เพิ่มการสั่งซื้อ V2</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <form-order :action="`add`"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import formOrder from './form.order_new';
  export default {
    data() {
      return {
        permission:{
          appSlug: 'order',
          actionData: {}
        },
        item: {
          id: '',
          code: '',
          customer_status: false,
          customer_id: '',
          customer_name: '',
          created: '',
          updated: '',
          date_order: new Date(), // ไม่มีใน class/erd
          status_order: '',
          status_payment: '',
          status_delivery: '',
          remark: '',
          total: 0,
          cost_product: '',
          cost_shipping: '',
          cost_discount: '',
          cost_extra: '',
          price_product: '',
          price_shipping: '',
          price_extra: '',
          user_id: '',
          sales_id: '',
          delivery_phone: '',
          delivery_contact: '',
          delivery_address: '',
          delivery_location: '',
          store_id: '',
          status_refund: '',
          price_refund: '',
          // delivery
          expected_delivery_date: '',
          expected_delivery_time: '',
          // customer
          email: '',
          address: '',
          company_name: '',
          tax_id: '',
          channel_id: 1,
          billing_name: '',
          billing_phone: '',
          billing_address: '',
          google_map_url : '',
          temple_hall: '',
        }
      }
    },
    methods: {
      async getOrderCode(){
        const result = await this.HttpServices.getData(`/ab_order/getOrderCode`);
        this.item.code = result.data;
      }
    },
    components: {
      formOrder
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      // this.getOrderCode();
    },
  };
</script>
<style>

</style>
